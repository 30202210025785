<template>
  <b-row
    class="align-items-center mb-3"
    :class="{ 'justify-content-center': center }"
  >
    <b-col :md="$slots.action ? 6 : 12">
      <h3 :class="{ 'text-center': center }">
        <slot></slot>
      </h3>
    </b-col>
    <b-col md="6" class="d-flex" v-if="$slots.action">
      <div class="ml-md-auto ml-lg-auto ml-xl-auto">
        <slot name="action"></slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    center: Boolean,
  },
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 700;
}
</style>
