<template>
  <div class="btn-scroll-top-container">
    <transition appear name="scrollBtn">
      <b-button
        pill
        v-if="scrollTopBtnTrigger"
        variant="primary"
        class="btn-scroll-top"
        @click="scrollToTop"
      >
        <b-icon icon="chevron-up"></b-icon>
      </b-button>
    </transition>
  </div>
</template>

<script>
window.onscroll = () => {}
export default {
  data() {
    return {
      scrollTopBtnTrigger: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (document.documentElement.scrollTop > 500)
        this.scrollTopBtnTrigger = true
      else this.scrollTopBtnTrigger = false
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-scroll-top-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  button {
    width: 50px;
    height: 50px;
    padding: 0 10px 4px 10px !important;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
  }
}
.scrollBtn-enter-active {
  transition: all 0.3s ease;
}
.scrollBtn-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.scrollBtn-enter,
.scrollBtn-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
