import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'

const options = {
  pk:
    'pk_live_51L0SqSIOStoHCRM9GIFS7WfAgri7Gy8aK0SzoF1SHI0utVGnlk7XAOctpnoXwc1hWOZ26O93qcqqH3nd4wiHToVl001SHFLWiP',
  testMode: true, // Boolean. To override the insecure host warning
  locale: 'fr',
}

Vue.use(StripePlugin, options)
