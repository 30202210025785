<template>
  <div
    class="multi-select-container multi-select-site-nav"
    :class="{ openned: open }"
  >
    <b-icon class="left-icon" :icon="leftIcon"></b-icon>
    <multiselect
      v-model="dataValue"
      @open="open = true"
      @input="changeValue"
      @close="open = false"
      :options="optionsOrdered"
      :multiple="false"
      searchable
      :showLabels="false"
      :group-select="false"
      :placeholder="placeholder"
      :allow-empty="false"
      track-by="name"
      label="name"
      class="mt-1"
    >
      <span slot="noResult">Aucun résultat</span>
      <span slot="caret" class="caret">
        <b-icon
          :class="{ rotate: open, out: !open }"
          icon="chevron-up"
          scale="1.12"
        ></b-icon
      ></span>
    </multiselect>
  </div>
</template>

<script>
export default {
  created() {
    if (this.selectedSite) this.dataValue = this.selectedSite
  },
  data() {
    return {
      open: false,
      dataValue: null,
    }
  },
  props: {
    value: [Array, Object],
    options: Array,
    placeholder: String,
    leftIcon: String,
    selectedSite: Object,
  },
  methods: {
    changeValue() {
      this.$emit('input', this.dataValue)
    },
  },
  computed: {
    storeSelectedSite() {
      return this.$store.state.user.selectedSite
    },
    optionsOrdered() {
      const optionsCopy = JSON.parse(JSON.stringify(this.options))
      const ordered = optionsCopy.sort((a, b) => a.name.localeCompare(b.name))
      return ordered
    },
  },
  watch: {
    storeSelectedSite: {
      deep: true,
      handler: function() {
        this.dataValue = this.storeSelectedSite
      },
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.left-icon {
  transition: 0.2s;
  position: absolute;
  left: 13px;
  top: 33%;
  color: #b0b8c9;
}

.rotate {
  transition: 0.1s ease-out;
}

.out {
  transition: 0.1s ease-out;
  transform: rotate(180deg);
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  color: $h-input-placeholder !important;
  opacity: 1; /* Firefox */
  font-size: 1rem !important;
}
/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: $h-input-placeholder !important;
}
</style>
