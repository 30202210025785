<template>
  <div class="locale-changer">
    <h-select
      @input="changeLanguage"
      v-model="language"
      :placeholder="$t('player.playerTypeLabel')"
      :options="langs"
      notClearable
      text-field="text"
      :flag="language.flag"
      class="bg-white"
      appendToBody
      calculatePosition
    ></h-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: this.$i18n.locale,
      langs: [
        { value: 'fr', flag: 'fr', text: 'Français' },
        { value: 'en', flag: 'gb', text: 'English' },
        { value: 'es', flag: 'es', text: 'Spanish' },
        { value: 'it', flag: 'it', text: 'Italian' },
      ],
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.language.value
      localStorage.setItem('lang', JSON.stringify(this.language))
    },
  },
  created() {
    const savedLanguage = localStorage.getItem('lang')
    if (savedLanguage) {
      const savedLanguageParsed = JSON.parse(savedLanguage)
      this.language = savedLanguageParsed
      this.$i18n.locale = savedLanguageParsed.value
    } else {
      const lang = navigator.language.split('-')[0]
      this.$i18n.locale = lang
    }

    const result = this.langs.find((lang) => lang.value === this.$i18n.locale)
    if (result) this.language = result
  },
}
</script>

<style lang="scss" scoped></style>
