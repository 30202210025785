import client from './axios.js'

export default {
  getMediaMessages(params) {
    return client.post('/media/mediaschedule/', params)
  },
  getScheduleList(idBrand) {
    return client.get(`/message/mobile/list/${idBrand}`)
  },
  getSchedule(idSchedule) {
    return client.get(`/message/mobile/schedule/${idSchedule}`)
  },
  getFullSchedule(idSchedule) {
    return client.get(`/schedule/${idSchedule}`)
  },
  getTags(idBrand) {
    return client.get(`/tag/message/${idBrand}`)
  },
  //A utiliser pour l'enregistrement
  getMobileTags(idBrand) {
    return client.get(`/tag/message/mobile/${idBrand}`)
  },
  createSchedule(schedule) {
    return client.post('/message/mobile/schedule', schedule)
  },
  createMessageSchedule(schedule) {
    return client.post('/schedule/ScheduleFullUpload', schedule)
  },
  editScheduleMobile(schedule) {
    return client.put('/message/mobile/schedule', schedule)
  },
  deleteSchedule(idSchedule) {
    return client.delete(`/message/mobile/schedule/${idSchedule}`)
  },
  getCompositingOptions(idBrand) {
    return client.get(`/brand/${idBrand}/compositing`)
  },
}
