import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSun } from '@fortawesome/free-solid-svg-icons'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faWind } from '@fortawesome/free-solid-svg-icons'
import { faSnowflake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSun)
library.add(faCloud)
library.add(faCloudShowersHeavy)
library.add(faBolt)
library.add(faWind)
library.add(faSnowflake)

Vue.component('font-awesome-icon', FontAwesomeIcon)
