import client from './axios.js'

export default {
  getTagsFilter(brand) {
    return client.get(`/tag/site/${brand}`)
  },
  refreshPlayerData(idPlayer) {
    return client.get(`/player/${idPlayer}/refresh`)
  },
  getPlayer(id) {
    if (!id) return
    return client.get(`/player/${id}`)
  },
  getPlayers(playerFilter) {
    return client.post('/player/list', playerFilter)
  },
  getPlaylist(params) {
    return client.post('/playlist/list', params)
  },
  getPlaylistItemAudio(id, type) {
    return client.get(`/media/preview/${type === 2 ? 'speak' : 'song'}/${id}`)
  },
  setPlayerVolume(params) {
    return client.post('/player/volume', params)
  },
  setChangeFlux(params) {
    return client.post('/player/currentplaybacktype', params)
  },
  skipCurrentTrack(params) {
    return client.post('/player/skipcurrenttrack', params)
  },
  sendTrigger(params) {
    return client.post('/player/trigger/execute', params)
  },
  unpairPlayer(idPlayer) {
    return client.post(`/player/${idPlayer}/unpair`)
  },
}
