<template>
  <b-form-group :label="label">
    <b-form-textarea
      :value="value"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :rows="rows"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: [Object, Array, Number, String],
    label: String,
    placeholder: String,
    rows: [Number, String],
  },
}
</script>

<style lang="scss" scoped>
label {
  color: #737e96 !important;
  font-weight: 600;
  background: none;
}
.form-group {
  textarea {
    border: 1px solid #ebeff6;
    border-radius: 7px;
    background-repeat: no-repeat;
    color: $h-input-color !important;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: $primary;
    }
  }
}
</style>
