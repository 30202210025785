<template>
  <div>
    <div class="head-background"></div>
    <navBar v-if="user" />
    <router-view />
  </div>
</template>
<script type="text/javascript"></script>
<script>
import NavBar from '@/components/NavBar.vue'
import { mapState } from 'vuex'
export default {
  components: { NavBar },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      selectedSite: (state) => state.user.selectedSite,
    }),
  },
  mounted() {
    this.$zendesk.load('d5c739b3-e377-421b-bd91-01a8e7468f1a')
    this.$zendesk.$on('loaded', (event) => {
      this.$zendesk.zE('webWidget', 'setLocale', this.$i18n.locale)
      this.$zendesk.zE('webWidget', 'prefill', {
        name: {
          value: this.user
            ? this.user.firstname + ' ' + this.user.lastname
            : '',
        },
        email: {
          value: this.user ? this.user.email : '',
        },
      })
      this.$zendesk.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: [
              {
                id: 16172074256018,
                prefill: {
                  '*': this.user ? this.user.email : '',
                },
              },
              {
                id: 360016425120,
                prefill: {
                  '*':
                    this.user.visibleBrands[0].name +
                    ' - ' +
                    this.selectedSite?.name,
                },
              },
              {
                id: 360020142080,
                prefill: {
                  '*': 'myHubcast',
                },
              },
            ],
          },
        },
      })
      this.$zendesk.zE('webWidget', 'show')
    })
  },
}
</script>

<style lang="scss" scoped>
.head-background {
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  height: 250px;
  background: #e8ecf4;
  background: linear-gradient(
    45deg,
    $head-gradient-from 0%,
    $head-gradient-to 100%
  );
}
@media screen and (max-width: 991.5px) {
  .head-background {
    top: -20px;
    height: 192px;
  }
}
@media screen and (max-width: 767.5px) {
  .head-background {
    top: -20px;
    height: 122px;
  }
}
</style>
