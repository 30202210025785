<template>
  <div class="planif-container">
    <div class="planif-main">
      <div
        class="right-actions justify-content-end no-white-space d-flex align-items-center"
      >
        <div
          class="w-50 d-none d-xl-flex justify-content-end no-white-space align-items-center"
        >
          <b-col v-if="player" cols="12" xl="10" lg="8">
            <h-select-flux
              v-if="fluxOptions && fluxOptions.length > 0"
              class="w-100 mr-3"
              v-model="flux"
              :placeholder="$t('planification.chooseAProgram')"
              :playerType="player.offer"
              :options="fluxOptions"
              :disabled="fluxOptions.length === 1"
              @input="changeProgram"
            ></h-select-flux>
          </b-col>
        </div>
        <div class="d-flex d-xl-none" v-if="player && fluxOptions">
          <h-button
            flat
            grey
            class="mr-3"
            id="tooltip-filters"
            v-if="
              (fluxOptions && fluxOptions.length > 0) || visibleSites.length > 1
            "
          >
            <b-icon icon="filter" class="w-15px mr-0 mr-sm-2" font-scale="1">
            </b-icon>
            <span class="d-none d-sm-inline">{{ $t('player.filters') }}</span>
          </h-button>
          <b-tooltip
            class="musique-tooltip"
            v-if="fluxOptions.length > 0 || visibleSites.length > 1"
            target="tooltip-filters"
            variant="light"
            placement="bottom"
          >
            <b-row class="row-tooltip">
              <b-col cols="12" class="pr-1">
                <h-select-flux
                  v-if="fluxOptions.length > 0 && player"
                  class="w-100"
                  v-model="flux"
                  :placeholder="$t('planification.chooseAProgram')"
                  :playerType="player.offer"
                  :options="fluxOptions"
                  @input="getSchedules()"
                >
                </h-select-flux>
              </b-col>
            </b-row>
          </b-tooltip>
        </div>

        <h-button
          v-if="
            $store.state.user &&
              $store.state.user.user &&
              $store.state.user.user.rights &&
              $store.state.user.user.rights.includes(
                'MYHUBCAST_SCHEDULE_MUSIC_SEQUENCE'
              ) &&
              this.flux > 0
          "
          :disabled="!player || !allowToGenerate"
          green
          @click="validatePlanif()"
        >
          <b-icon
            icon="calendar-check-fill"
            class="w-15px mr-0 mr-sm-2"
            font-scale="1"
          >
          </b-icon>
          <span class="d-none d-sm-inline">{{
            $t('planification.validate')
          }}</span>
        </h-button>
      </div>
      <div class="relative h-100">
        <transition name="fade">
          <div
            v-if="fetchingSchedules"
            class="mask d-flex align-items-center justify-content-center"
          >
            <div class="text-white d-flex align-items-center">
              <b-spinner class="spinner mr-3"></b-spinner>
              <span class="lead"
                >{{
                  saving ? $t('planification.save') : $t('planification.get')
                }}
                {{ $t('planification.loadingMessage') }}</span
              >
            </div>
          </div>
        </transition>
        <FullCalendar ref="fullCalendar" :options="calendarOptions">
          <template v-slot:eventContent="arg">
            <b>{{ formatTimeText(arg.timeText) }}</b> <br />
            <i>{{ arg.event.title }}</i>
            <img
              v-if="
                arg.event.extendedProps.imgSkeleton &&
                  arg.event.extendedProps.imgSkeleton > 0
              "
              :src="
                (env === 'production' ? '/api' : 'http://localhost:5000/api') +
                  `/skeleton/${arg.event.extendedProps.imgSkeleton}/cover`
              "
              alt="image skeleton"
            />
          </template>
        </FullCalendar>
      </div>
    </div>
    <ModalValidatePlanif
      v-if="player && showValidatePlanifModal"
      :showValidatePlanifModal="showValidatePlanifModal"
      :player="selectedSite"
      :editingFlux="flux"
      :currentFlux="player.extended.currentStream"
      @close="showValidatePlanifModal = false"
    />
  </div>
</template>

<script>
import ModalValidatePlanif from '@/components/modals/ModalValidatePlanif'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { EventBus } from '@/components/base/event-bus.js'
import musiqueService from '@/services/musiqueService'
import playerService from '@/services/playerService'

import dateFormat from '@/components/dateFormat'
import { mapState } from 'vuex'

export default {
  components: {
    FullCalendar,
    ModalValidatePlanif,
  },
  data() {
    return {
      saving: false,
      fluxLoaded: false,
      flux: 0,
      startDate: null,
      endDate: null,
      player: null,
      allowToGenerate: true,
      showValidatePlanifModal: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        eventResizableFromStart: true,
        scrollTime: '08:00:00',
        slotEventOverlap: false,
        nowIndicator: false,
        locale: this.$i18n.locale,
        events: this.events,
        allDaySlot: false,
        firstDay: 1,
        droppable: false,
        progressiveEventRendering: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
          omitZeroMinute: true,
        },
        eventTimeFormat: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          meridiem: false,
        },
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: '',
        },
        initialView: 'timeGridWeek',
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventDidMount: this.eventDidMount,
        eventClick: this.handleEventClick,
        eventReceive: this.handleDrop,
        eventsSet: this.handleEvents,
        datesSet: this.datesSet,
        eventResize: this.resizeEvent,
        eventDrop: this.resizeEvent,
      },
      currentEvents: [],
    }
  },
  computed: {
    visibleSitesComp() {
      if (this.visibleSites) {
        const sitesComp = this.visibleSites.map((site) => ({
          label: site.name,
          value: site.id,
        }))
        return sitesComp
      } else return null
    },
    fluxOptions() {
      return this.streamOptions
        ? this.streamOptions.filter(
            (stream) => stream.category === 1 && stream.idStream !== -1
          )
        : []
    },
    env() {
      return process.env.NODE_ENV
    },
    ...mapState({
      visibleSites: (state) =>
        state.user && state.user.user ? state.user.user.visibleSites : [],
      fetchingSchedules: (state) => state.musique.fetchingSchedules,
      selectedSite: (state) => state.user.selectedSite,
      streamOptions: (state) => state.musique.streams,
    }),
  },
  methods: {
    formatTimeText(time) {
      const timeSplit = time.split('-')
      if (timeSplit.length > 1) return timeSplit[0] + ' - ' + timeSplit[1]
      else return timeSplit[0] + ' - 00:00'
    },
    datesSet(info) {
      this.$toast.clear()
      this.startDate = info.startStr
      this.endDate = info.endStr
      if (!this.fetchingSchedules) this.getSchedules()
    },
    changeProgram(val) {
      this.flux = val
      this.getSchedules()
    },
    async fetchPlayer() {
      this.$store.dispatch('musique/_fetchingSchedulesState', true)
      const idSite = this.selectedSite ? this.selectedSite.id : null
      try {
        await this.getPlayerInfo(idSite)
      } catch (e) {
        if (e.response && e.response.status !== 401) {
          this.$toast.error(
            "Une erreur s'est produite, veuillez réessayer ultérieurement"
          )
        }
        this.$store.dispatch('musique/_fetchingSchedulesState', false)
        return
      }
    },
    async getSchedules() {
      if (this.flux < 1) {
        this.$store.dispatch('musique/_fetchingSchedulesState', false)
        return
      }
      this.$store.dispatch('musique/_fetchingSchedulesState', true)
      const params = {
        idSite: parseInt(this.selectedSite.id),
        idBrand: this.$store.state.user.user.visibleBrands[0].id,
        from: dateFormat.encodeServerDate(this.$moment(this.startDate)),
        to: dateFormat.encodeServerDate(this.$moment(this.endDate)),
        typeMedia: 10,
        idStream: this.flux,
      }
      try {
        const res = await musiqueService.getScheduleList(params)
        this.drawEvents(res.data)
      } catch (e) {
        this.$toast.error(this.$t('errors.generic'))
        this.saving = false
        this.$store.dispatch('musique/_fetchingSchedulesState', false)
      }
    },
    async getPlayerInfo(idSite) {
      if (!idSite) return
      const res = await playerService.getPlayer(idSite)
      this.player = res.data
    },
    async fetchStreams() {
      await this.$store.dispatch('musique/_getStreams')
      if (this.streamOptions && this.streamOptions.length > 0) {
        const selectedFluxAvailable = this.fluxOptions.filter((flux) => {
          return flux.idStream === this.player.extended.currentStream
        })
        if (this.fluxOptions.length === 0) return

        this.flux =
          selectedFluxAvailable.length > 0
            ? selectedFluxAvailable[0].idStream
            : this.fluxOptions[0].idStream
      } else this.flux = 0
    },
    drawEvents(days) {
      if (this.$refs.fullCalendar) {
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.removeAllEvents()
        let minTimeEvent = '08:00'
        days.forEach((day, index) => {
          const dayString = this.$moment(day.dt).format('YYYY-MM-DD')
          day.scheduleId.forEach((schedule) => {
            if (index < 7) {
              let obj = {
                active: schedule.active,
                idSkeleton: schedule.idSkeleton,
                groupId: schedule.scheduleId,
                title: schedule.title,
                editable:
                  this.$store.state.user?.user?.rights?.includes(
                    'MYHUBCAST_SCHEDULE_MUSIC_SEQUENCE'
                  ) && this.flux > 0,
                startDate: schedule.startDate,
                endDate: schedule.endDate,
                startTimes:
                  schedule.schedTime && schedule.schedTime[0]
                    ? [
                        schedule.schedTime[0].formatedScheduleTime
                          .split('>')[0]
                          .replace(/\s/g, ''),
                      ]
                    : ['00:00'],
                endTimes:
                  schedule.schedTime && schedule.schedTime[0]
                    ? [
                        schedule.schedTime[0].formatedScheduleTime
                          .split('>')[1]
                          .replace(/\s/g, ''),
                      ]
                    : ['00:00'],
                backgroundColor: schedule.color,
                imgSkeleton:
                  schedule.idSkeletonImage > 0
                    ? schedule.idSkeletonImage
                    : null,
                scheduleId: schedule.scheduleId,
                dayOfWeek: schedule.dayOfWeek,
                siteId: schedule.siteId,
              }
              obj.startTimes.forEach((time, i) => {
                if (time < minTimeEvent) {
                  minTimeEvent = time
                }
                obj.start = dayString + 'T' + time
                obj.end =
                  dayString +
                  'T' +
                  (obj.endTimes[i] == '00:00' ? '24:00' : obj.endTimes[i])

                calendarApi.addEvent(obj)
              })
            }
          })
        })

        calendarApi.scrollToTime(minTimeEvent + ':00')

        this.$store.dispatch('musique/_fetchingSchedulesState', false)
        this.saving = false
      }
    },
    handleEventClick(planningInfo) {
      if (
        this.$store.state.user?.user?.rights?.includes(
          'MYHUBCAST_SCHEDULE_MUSIC_SEQUENCE'
        ) &&
        this.flux > 0
      ) {
        let calendarApi = planningInfo.view.calendar
        const event = {
          calendarApi: calendarApi,
          event: planningInfo.event,
          flux: this.flux,
        }
        EventBus.$emit('calendarApi', event)
        this.$store.dispatch('musique/_openCloseSideBar', true)
        this.$store.dispatch(
          'musique/_openPlanning',
          JSON.parse(JSON.stringify(planningInfo.event))
        )
      }
    },
    handleDrop(arg) {
      this.handleEventClick(arg)
    },
    dayOfWeekIndex(start) {
      const moment = this.$moment(start)
      const dow = moment.day()
      return dow
    },
    async resizeEvent(eventResizeInfo) {
      const resizedEvent = eventResizeInfo.event
      let eventToSend = {
        active: resizedEvent.extendedProps.active,
        idBrand: this.$store.state.user.user.visibleBrands[0].id,
        idSkeleton: parseInt(resizedEvent.extendedProps.idSkeleton),
        scheduleId: parseInt(resizedEvent.extendedProps.scheduleId),
        siteId: [this.selectedSite.id],
        idStream: this.flux,
        startTimes: [this.$moment(resizedEvent.start).format('HH:mm')],
        endTimes: [this.$moment(resizedEvent.end).format('HH:mm')],
        dayOfWeek: resizedEvent.extendedProps.dayOfWeek,
      }

      const endTimeDrag = this.$moment(resizedEvent.end).format('HH:mm')
      if (
        resizedEvent.start.getDay() !== resizedEvent.end.getDay() &&
        endTimeDrag !== '00:00'
      ) {
        eventResizeInfo.revert()
        return
      } else if (
        eventResizeInfo.oldEvent.start.getDay() !== resizedEvent.start.getDay()
      ) {
        const indexStart =
          this.dayOfWeekIndex(resizedEvent.start) === 0
            ? 7
            : this.dayOfWeekIndex(resizedEvent.start)
        const oldIndex =
          this.dayOfWeekIndex(eventResizeInfo.oldEvent.start) == 0
            ? 7
            : this.dayOfWeekIndex(eventResizeInfo.oldEvent.start)
        const moveIndex = indexStart - oldIndex

        if (moveIndex < 0) {
          eventToSend.dayOfWeek = eventToSend.dayOfWeek >> Math.abs(moveIndex)
        } else {
          eventToSend.dayOfWeek = eventToSend.dayOfWeek << Math.abs(moveIndex)
        }
      }
      if (eventToSend.dayOfWeek > 128) eventResizeInfo.revert()
      else {
        await musiqueService.postSchedule(eventToSend)
        this.saving = true
        this.getSchedules()
      }
    },
    eventDidMount(arg) {
      if (arg) arg.el.setAttribute('data-id', arg.event.id + arg.event.start)
    },
    async validatePlanif() {
      const idSite = this.selectedSite.id
      try {
        this.allowToGenerate = false
        await musiqueService.generatePlaylist(idSite)
        this.showValidatePlanifModal = true
      } catch (err) {
        this.$toast.error(this.$t('errors.generic'))
      } finally {
        setTimeout(() => {
          this.allowToGenerate = true
        }, 10000)
      }
    },
  },
  watch: {
    selectedSite: {
      deep: true,
      handler: async function() {
        await this.fetchPlayer()
        await this.fetchStreams()
        await this.getSchedules()
      },
    },
  },
  async created() {
    await this.fetchPlayer()
    await this.fetchStreams()
    await this.getSchedules()
  },
  mounted() {
    EventBus.$on('fetchSchedules', () => {
      this.saving = true
      this.getSchedules()
    })
  },
  beforeDestroy() {
    EventBus.$off('fetchSchedules')
  },
}
</script>

<style lang="scss">
.relative {
  position: relative;
}
.mask {
  border-radius: 10px;
  position: absolute;
  cursor: wait;
  pointer-events: initial;
  top: 50px;
  height: calc(100% - 50px);
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 2;
}
h4 {
  margin-bottom: 35px !important;
  font-weight: 600 !important;
}
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b {
  /* used for event dates/times */
  margin-right: 3px;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 1px;
}
.fc-scroller {
  overflow: auto !important;
}
.planif-container {
  display: flex;
  font-size: 14px;
  height: 80vh;
}
.bg-grey {
  background: lightgray;
}
.planif-main {
  flex-grow: 1;
  height: calc(100vh - 152px);
}
.right-actions {
  height: 46px;
  margin-bottom: -40px;
  position: relative;
  max-width: 70%;
  margin-left: auto;
  z-index: 2;
}
.fc {
  /* the calendar root */
  max-width: 1400px;
  margin: 0 auto;
}
.fc-header-toolbar {
  justify-content: flex-start !important;
  .fc-toolbar-title {
    margin-left: 12px;
    font-size: 1.5em;
  }
}
.fc-col-header-cell {
  background: #f5f7fb;
  a {
    color: #323f5a !important;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}
.fc-scrollgrid {
  border: 0 !important;
}
.fc-scrollgrid-sync-inner {
  padding: 14px 5px;
}
.fc-timegrid {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ebeff6;
}
.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
.fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor,
.fc-timegrid-axis {
  background: #ebeff6;
  color: #323f5a;
}
.fc-timegrid-slots tr,
.fc-timegrid-slots td,
.fc-timegrid-slots th {
  border-color: #ebeff6;
}
.fc-timegrid-event {
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  border: 0;
}
.b-tooltip.musique-tooltip {
  background: #f8f9fa !important;
  opacity: 1 !important;
  box-shadow: 0 2px 4px 0 #6d6d6d;
  border-radius: 10px;
  z-index: 100;
}
.row-tooltip,
.b-tooltip.musique-tooltip {
  width: 350px;
}
.tooltip-inner {
  max-width: inherit;
}
</style>
