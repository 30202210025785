import playerService from '@/services/playerService.js'
const getDefaultState = () => {
  return {
    tagsFilter: [],
    players: [],
    playersError: false,
    playersFilters: {
      IdBrand: 0,
      OfferType: 1, //only radio players
      SiteName: '',
      SiteTagIds: [],
      PlayerType: 0,
      PlayerState: 0,
      pageIndex: 1,
      pageLength: 20,
    },
    totalResults: null,
    playersLoaded: false,
    fetchingPlayer: false,
    playerNextLoaded: false,
  }
}
const player = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_TAGS(state, tags) {
      state.tagsFilter = tags
    },
    SET_ID_BRAND(state, idBrand) {
      state.playersFilters.IdBrand = idBrand
    },
    SET_PLAYER_FILTERS(state, filters) {
      const obj = Object.assign(state.playersFilters, filters)
      state.playersFilters = obj
    },
    INCREMENT_PAGE(state, setPage) {
      if (setPage) state.playersFilters.pageIndex = setPage
      else state.playersFilters.pageIndex++
    },
    PUSH_PLAYERS(state, players) {
      players.forEach((player) => state.players.push(player))
      state.playersLoaded = true
      state.playerNextLoaded = true
    },
    TOTAL_RESULTS(state, results) {
      state.totalResults = results
    },
    CLEAR_PLAYERS(state) {
      state.players = []
    },
    PLAYERS_LOADING(state) {
      state.players = []
      state.playersLoaded = false
    },
    PLAYERS_NEXT_LOADING(state) {
      state.playerNextLoaded = false
    },
    FETCHING_PLAYER(state, payload) {
      state.fetchingPlayer = payload
    },
    SERVER_ERROR(state, payload) {
      state.players = []
      state.playersError = payload
      state.playersLoaded = true
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async _getTagsFilters({ commit, rootState }) {
      const visibleBrand = rootState.user.user.visibleBrands[0].id
      const res = await playerService.getTagsFilter(visibleBrand)
      commit('SET_TAGS', res.data)
    },
    _setplayersFilters({ commit }, filters) {
      commit('SET_PLAYER_FILTERS', filters)
    },
    _incrementPage({ commit }, setPage) {
      commit('INCREMENT_PAGE', setPage)
    },
    async _getPlayers({ commit, rootState }, playerFilter) {
      if (playerFilter.pageIndex === 1) commit('PLAYERS_LOADING')
      else commit('PLAYERS_NEXT_LOADING')
      commit('SET_ID_BRAND', rootState.user.user.visibleBrands[0].id)
      const res = await playerService.getPlayers(playerFilter)
      commit('PUSH_PLAYERS', res.data.result)
      commit('TOTAL_RESULTS', res.data.total)
    },
    _fetchingPlayer({ commit }, payload) {
      commit('FETCHING_PLAYER', payload)
    },
    _clearPlayers({ commit }) {
      commit('CLEAR_PLAYERS')
    },
    _resetPlayerState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {
    selectedTags: (state) => {
      if (state.tagsFilter.length) return state.tagsFilter
    },
  },
}

export default player
