<template>
  <b-modal v-model="modalShow" hide-header hide-footer centered>
    <div class="px-2">
      <div class="d-block text-center">
        <h4>
          Planification validée
        </h4>
      </div>
      <div class="mt-5 w-50 mx-auto">
        <b-img
          :src="require('@/assets/svg/illu-validate-planif.svg')"
          fluid
          alt="password changed"
        ></b-img>
      </div>
      <div class="my-5" v-if="!askForChangingFlux">
        <p class="lead large text-center">
          {{
            'La planification a été mise à jour, la playlist sera modifiée dans quelques minutes.'
          }}
        </p>
      </div>
      <div class="mt-5 mb-3 d-flex flex-column mt-3">
        <div v-if="askForChangingFlux">
          <p class="lead large text-center">
            {{
              'Le player ' +
                this.player.name +
                ' joue actuellement un autre flux, souhaitez-vous le changer pour écouter dès maintenant votre nouvelle planification ?'
            }}
          </p>
          <div class="d-flex justify-content-center">
            <h-button @click="close" outline red class="px-3 mr-3">
              {{ $t('actions.no') }}
            </h-button>
            <h-button
              @click="changeFlux"
              green
              :disabled="changeFluxLoading"
              class="px-3 mr-3"
            >
              <template v-if="changeFluxLoading">
                <b-spinner small class="mb-1 mr-2"></b-spinner>
                {{ $t('login.loading') }}
              </template>
              <template v-else>
                {{ $t('planification.changePlaylistConfirm') }}
              </template>
            </h-button>
          </div>
        </div>
        <h-button v-else @click="close" outline grey class="mx-auto px-3 mr-3">
          {{ $t('actions.close') }}
        </h-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import playerService from '@/services/playerService'
export default {
  props: {
    showValidatePlanifModal: Boolean,
    player: {
      type: Object,
      required: true,
    },
    editingFlux: {
      type: Number,
      required: true,
    },
    currentFlux: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      changeFluxLoading: false,
    }
  },
  methods: {
    async changeFlux() {
      this.changeFluxLoading = true
      const params = {
        IdSite: this.storeSelectedSiteId,
        IdStream: this.editingFlux,
        playbackType: 0,
        idSkeleton: 0,
      }
      try {
        await playerService.setChangeFlux(params)
        this.$toast.success(
          'Le flux du player a été modifié avec succès et prendra effet au prochain changement de titre de votre radio.'
        )
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite, veuillez réessayer ultérieurement"
        )
      } finally {
        this.changeFluxLoading = false
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    askForChangingFlux() {
      return this.editingFlux > 0
        ? this.currentFlux !== this.editingFlux
        : false
    },
    storeSelectedSiteId() {
      return this.$store.state.user?.selectedSite?.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
