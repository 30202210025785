const getDefaultState = () => {
  return {
    customerId: null,
  }
}
const stripe = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_CUSTOMER_ID(state, payload) {
      state.customerId = payload
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    _resetUserState({ commit }) {
      commit('RESET_STATE')
    },
  },
}

export default stripe
