import moment from 'moment'
export default {
  // encode a current culture string date
  // if current user culture is french -> strLocalDate string format must be : DD/MM/YYYY
  // if current user culture is english -> strLocalDate string format must be : MM/DD/YYYY
  encodeServerDate(strLocalDate) {
    const currentCulture = 'fr'
    let momentDate = ''
    if (strLocalDate != null && strLocalDate != '') {
      if (
        moment(strLocalDate, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format(
          'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
        ) === strLocalDate
      )
        return strLocalDate
      if (currentCulture == 'fr') {
        momentDate = moment(strLocalDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
        )
      } else {
        momentDate = moment(strLocalDate, 'MM/DD/YYYY').format(
          'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
        )
      }
    } else {
      momentDate = moment('01-01-2099').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
    }
    return momentDate
  },
  decodeServerDate(strdate) {
    //Decode upcoming date from server to moment date
    return moment(strdate)
  },
}
