<template>
  <div
    id="app"
    class="h-100"
    :class="{
      'background-image-page': loginRouteNames.includes($route.name),
      'no-m': simpleRouteNames.includes($route.name),
    }"
  >
    <component v-if="$route.name" :is="layout"></component>
  </div>
</template>

<script>
import loginLayout from '@/layouts/loginLayout.vue'
import appLayout from '@/layouts/appLayout.vue'
import simpleLayout from '@/layouts/simpleLayout.vue'

export default {
  data() {
    return {
      loginRouteNames: ['login', 'register-test'],
      simpleRouteNames: ['design', '404', 'share', 'register'],
    }
  },
  name: 'app',
  components: {
    loginLayout,
    appLayout,
    simpleLayout,
  },
  computed: {
    layout() {
      if (this.loginRouteNames.includes(this.$route.name)) return loginLayout
      else if (this.simpleRouteNames.includes(this.$route.name))
        return simpleLayout
      else return appLayout
    },
  },
}
</script>

<style lang="scss">
//Import variables
@import '@/assets/scss/_variables.scss';
// Import Bootstrap and BootstrapVue source SCSS files
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
//Import site styles
@import '@/assets/scss/_main.scss';
</style>
