<template>
  <b-button
    pill
    :disabled="disabled"
    class="border-0"
    :size="size"
    :class="{
      purple: purple,
      blue: blue,
      red: red,
      green: green,
      grey: grey,
      outline: outline,
      flat: flat,
      transp: transp,
      orange: orange,
      small: small,
      p0: p0,
    }"
    @click="$emit('click')"
  >
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  props: {
    small: Boolean,
    size: String,
    disabled: Boolean,
    purple: Boolean,
    blue: Boolean,
    red: Boolean,
    green: Boolean,
    grey: Boolean,
    outline: Boolean,
    transp: Boolean,
    flat: Boolean,
    orange: Boolean,
    p0: Boolean,
  },
}
</script>

<style lang="scss" scoped>
button {
  font-weight: 600;
  padding: 10px 20px !important;
  transition: 0.2s;
  &.p0 {
    padding: 0 !important;
  }
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 7px -2px #d4d4d4;
  }
  &.small {
    font-size: 0.75rem !important;
    line-height: 1.3 !important;
  }
}
.purple {
  background: $h-purple;
  background: linear-gradient(90deg, $h-purple-blue 0%, $h-purple 100%);
}
.blue {
  background: $h-blue;
  background: linear-gradient(90deg, $h-blue 0%, $h-green-blue 100%);
}
.red {
  background: $h-light-red 100%;
  background: linear-gradient(90deg, $h-red 0%, $h-light-red 100%);
}
.green {
  background: $h-green 100%;
  background: linear-gradient(90deg, $h-green 0%, $h-light-green 100%);
}
.transp:hover {
  transform: none;
  box-shadow: none;
  color: white !important;
  background: $primary !important;
}

.transp.orange {
  color: $primary;
  background: rgba($primary, 0.1);
}
.transp.orange.disabled:hover {
  transform: none;
  box-shadow: none;
  color: $primary !important;
  background: rgba($primary, 0.1) !important;
}
.transp.blue {
  color: $dark-blue;
  background: rgba($dark-blue, 0.1);
}
.transp.blue:hover {
  transform: none;
  box-shadow: none;
  background: $dark-blue !important;
  color: white !important;
}
.transp.grey {
  color: $h-main-text;
  background: rgba($h-main-text, 0.1);
}
.transp.grey:hover {
  transform: none;
  box-shadow: none;
  background: $h-main-text !important;
  color: white !important;
}
.flat {
  background: transparent;
  &:hover {
    box-shadow: none;
    transform: none;
  }
  &:active,
  &:focus {
    color: white !important;
  }
}
.flat.purple {
  color: $h-purple;
  &:active,
  &:focus {
    background: linear-gradient(90deg, $h-purple-blue 0%, $h-purple 100%);
  }
}
.flat.blue {
  color: $h-blue;
  &:hover {
    color: $dark-blue;
  }
  &:active,
  &:focus {
    color: $h-blue !important;
    background: none;
  }
}
.flat.grey {
  color: $h-main-text;
  &:hover {
    color: darken($h-main-text, 20%) !important;
  }
  &:active,
  &:focus {
    color: darken($h-main-text, 10%) !important;
    text-decoration: underline;
    background: none;
  }
}

.flat.orange {
  color: $primary;
  &:hover {
    color: darken($primary, 20%) !important;
  }
  &:active,
  &:focus {
    color: darken($primary, 10%) !important;
    text-decoration: underline;
    background: none;
  }
}

.flat.red {
  color: $h-flash-red;
  &:hover {
    color: darken($h-flash-red, 20%) !important;
  }
  &:active,
  &:focus {
    color: darken($h-flash-red, 10%) !important;
    text-decoration: underline;
    background: none;
  }
}

.outline {
  &:hover,
  &:active,
  &:focus {
    color: white !important;
    box-shadow: none;
    transform: none;
  }
}
.outline.blue {
  border: solid 1px $h-blue !important;
  color: $h-blue;
  background: transparent;
  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(90deg, $h-blue 0%, $h-green-blue 100%);
  }
}
.outline.purple {
  border: solid 1px $h-purple !important;
  color: $h-purple;
  background: transparent;
  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(90deg, $h-purple-blue 0%, $h-purple 100%);
  }
}
.outline.red {
  border: solid 1px $h-light-red !important;
  color: $h-light-red;
  background: transparent;
  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(90deg, $h-red 0%, $h-light-red 100%);
  }
}
.outline.grey {
  border: solid 1px $h-main-text !important;
  color: $h-main-text;
  background: transparent;
  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(
      90deg,
      $h-main-text 0%,
      darken($h-main-text, 10%) 100%
    );
  }
}
.btn.disabled {
  pointer-events: none;
  opacity: 0.45;
}
</style>
