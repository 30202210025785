<template>
  <div
    class="select-container select-flux"
    :class="{ openned: open, hasIcon: leftIcon, small: small }"
  >
    <div class="label pb-2" v-if="label">{{ label }}</div>
    <b-icon v-if="leftIcon" class="left-icon" :icon="leftIcon"></b-icon>
    <v-select
      class="select"
      :class="{ 'bg-white': bgWhite, 'shadow-sm': small }"
      label="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="optionsComp"
      :reduce="(option) => option.idStream || option.idSkeleton"
      :value="value"
      :searchable="searchable"
      :clearable="false"
      @search:focus="open = true"
      @search:blur="open = false"
      @input="$emit('input', $event)"
    >
      <template #selected-option="{ idImage, name }">
        <div>
          <img
            v-if="idImage > 0"
            :src="
              (env === 'production' ? '/api' : 'http://localhost:5000/api') +
                `/brand/stream/${idImage}/cover`
            "
            alt="flux img"
            width="27px"
            height="27px"
            class="mr-3 img-flux"
          />
          <img
            v-else
            :src="
              name === 'MA RADIO'
                ? require('@/assets/img/cover-ma-radio.png')
                : require('@/assets/img/cover-playlist.png')
            "
            alt="flux img"
            width="27px"
            class="img-flux mr-3"
          />{{ name }}
        </div>
      </template>

      <template #option="{ idImage, name }">
        <div class="d-flex align-items-center z-3">
          <img
            v-if="idImage > 0"
            :src="
              (env === 'production' ? '/api' : 'http://localhost:5000/api') +
                `/brand/stream/${idImage}/cover`
            "
            alt="flux img"
            width="40px"
            height="40px"
            class="img-flux mr-3"
          />
          <img
            v-else
            :src="
              name === 'MA RADIO'
                ? require('@/assets/img/cover-ma-radio.png')
                : require('@/assets/img/cover-playlist.png')
            "
            alt="flux img"
            width="40px"
            class="img-flux mr-3"
          />
          <div class="d-flex flex-column">
            <div class="font-weight-semibold">{{ name }}</div>
          </div>
        </div>
      </template>
      <template slot="no-options">
        Aucun résultat..
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><b-icon icon="chevron-down"></b-icon></span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      complete: false,
    }
  },
  props: {
    value: [Object, Array, Number, String],
    searchable: Boolean,
    options: Array,
    label: [Boolean, Number, String],
    placeholder: String,
    leftIcon: String,
    bgWhite: Boolean,
    small: Boolean,
    disabled: Boolean,
    playerType: [Number, String],
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
    optionsComp() {
      if (this.playerType)
        return this.options.filter((option) => option.type === this.playerType)
      else return this.options
    },
  },
}
</script>
<style lang="scss" scoped>
.img-flux {
  border-radius: 3px;
}
.small {
  height: 41px;
}
.label {
  color: #737e96 !important;
  font-weight: 600;
  background: none;
}
.select-container {
  position: relative;
}
.left-icon {
  transition: 0.2s;
  position: absolute;
  left: 13px;
  top: 33%;
  color: #b0b8c9;
}
.v-select {
  height: 100%;
  border-radius: 7px;
  border: 1px solid #ebeff6;
  color: $h-input-color !important;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  font-size: 1rem;
  color: #737e96 !important;
  div {
    border: none;
  }
}
.vs--disabled {
  background: $h-hover-color;

  .vs__open-indicator {
    background: none;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $h-input-placeholder;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $h-input-placeholder;
}
.v-select {
  --c-base: #fff; /* background */
  --c-theme: #f0f0f0; /* tags background; options hover */
  --c-border: #ccc; /* border color of root element and tags */
  --radius: 0.2em; /* border radius of root element and tags */
  --padd: 3px; /* tags spacing */
  --height: 3em; /* minimum height; can grow larger when `:multiple=true` */
}
</style>
