import messageService from '@/services/messageService.js'
const getDefaultState = () => {
  return {
    schedules: [],
    schedulesLoaded: false,
  }
}
const schedule = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_SCHEDULES(state, schedules) {
      state.schedules = schedules
      state.schedulesLoaded = true
    },
    CLEAR_SCHEDULES(state) {
      state.schedules = {}
      state.schedulesLoaded = false
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async _getSchedules({ commit, rootState }) {
      const res = await messageService.getScheduleList(
        rootState.user.user.visibleBrands[0].id
      )
      commit('SET_SCHEDULES', res.data)
    },
    async _clearSchedules({ commit }) {
      commit('CLEAR_SCHEDULES')
    },
    _resetScheduleState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {},
}

export default schedule
