import userService from '@/services/userService.js'
const getDefaultState = () => {
  return {
    user: null,
    selectedSite: null,
    logo: JSON.parse(localStorage.getItem('brand_logo')) || null,
    token: localStorage.getItem('access_token') || null,
  }
}

const user = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_SITES(state, sites) {
      state.user.visibleSites = sites
    },
    UPDATE_USER(state, user) {
      state.user.email = user.Email
      state.user.lastname = user.LastName
      state.user.firstname = user.FirstName
      // state.user = Object.assign(state.user, user)
    },
    SET_LOGO(state, logo) {
      state.logo = logo
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    CLEAR_LOCAL_STORAGE() {
      localStorage.removeItem('STORAGE_KEY')
      localStorage.removeItem('access_token')
      localStorage.removeItem('brand_logo')
    },
    SET_USER_IDENTITY(state, selectedIdentity) {
      state.user.visibleIdentities = []
      state.user.visibleIdentities.push(selectedIdentity)
    },
    SET_USER_BRAND(state, selectedBrand) {
      state.user.visibleBrands = []
      state.user.visibleBrands.push(selectedBrand)
    },
    SET_SELECTED_SITE(state, selectedSite) {
      state.selectedSite = selectedSite
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async _setUser({ commit }, user) {
      const token = user.token
      localStorage.setItem('access_token', token)
      commit('SET_TOKEN', token)
      commit('SET_USER', user)
    },
    _updateUser({ commit }, user) {
      commit('UPDATE_USER', user)
    },
    _setVisibleSites({ commit }, visibleSites) {
      commit('SET_SITES', visibleSites)
    },
    async _fetchBrand({ commit, dispatch, state }) {
      if (state.user.visibleBrands.length) {
        const idBrand = state.user.visibleBrands[0].id
        const logo = await userService.getLogo(idBrand)
        localStorage.setItem('brand_logo', JSON.stringify(logo.data))
        commit('SET_LOGO', logo.data)
      } else dispatch('_logOut')
    },
    async _logOut({ state, dispatch }) {
      await userService.logOutUser(state.user.id, state.user.token)
      dispatch('_clearSession')
    },
    _clearSession({ commit, dispatch }) {
      commit('CLEAR_LOCAL_STORAGE')
      dispatch('message/_resetMessageState', null, { root: true })
      dispatch('musique/_resetMusiqueState', null, { root: true })
      dispatch('player/_resetPlayerState', null, { root: true })
      dispatch('playlist/_resetPlaylistState', null, { root: true })
      dispatch('schedule/_resetScheduleState', null, { root: true })
      dispatch('user/_resetUserState', null, { root: true })
      dispatch('stripe/_resetUserState', null, { root: true })
    },
    _resetUserState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {
    token: (state) => {
      return state.user && state.user.token ? state.user.token : null
    },
    isSingleSite: (state) => {
      if (state && state.user && state.user.visibleSites)
        return state.user.visibleSites.length === 1
    },
    singleVisibieSite: (state) => {
      if (
        state &&
        state.user &&
        state.user.visibleSites &&
        state.user.visibleSites.length
      )
        return state.user?.visibleSites[0].id.toString()
    },
    logoSrc: (state) => {
      if (state.logo && state.logo.data)
        return `data:${state.logo.contentType};base64, ${state.logo.data}`
    },
    isAuthenticated: (state) => {
      return (
        state.user &&
        state.user.id &&
        state.user.token &&
        state.user.visibleBrands.length === 1 &&
        state.user.visibleIdentities.length === 1
      )
    },
    authorizedRoleUser: (state) => {
      if (!state.user || !state.user.token) return false
      else
        return (
          state.user.visibleBrands.length === 1 &&
          state.user.visibleIdentities.length === 1
        )
    },
  },
}

export default user
