<template>
  <div>
    <b-button-group class="d-flex button-group-select" v-if="options">
      <b-button
        @click="selectValue(option)"
        class="default"
        :class="{ active: option.value === selectVal.value }"
        v-for="(option, i) in options"
        :key="i"
        >{{ option.label }}</b-button
      >
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    value: Object,
  },
  data() {
    return {
      selectVal: { label: '', value: '' },
    }
  },
  methods: {
    selectValue(option) {
      this.selectVal = option
      this.$emit('select', option)
    },
  },
  mounted() {
    if (this.value) this.selectVal = this.value
  },
}
</script>

<style lang="scss" scoped>
.button-group-select {
  border: solid 1px #e9e9e9 !important;
  border-radius: 8px;
  overflow: hidden;
  :first-child {
    border-left: 0;
  }
  :last-child {
    border-right: 0;
  }
}
.default {
  background: white;
  color: #848c9c;
  border-color: #e9e9e9;
  border-top: 0;
  border-bottom: 0;
  transition: 0.2s;
  &:hover {
    background: #e9e9e9;
  }
  &:focus {
    box-shadow: none !important;
  }
  &.selected {
    background: #848c9c;
    color: white;
  }
  &:active {
    background: #525864 !important;
    border-color: #e9e9e9 !important;
  }
  &.active {
    background: #848c9c !important;
    border-color: #e9e9e9 !important;
  }
}
</style>
