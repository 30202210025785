var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button',{staticClass:"border-0",class:{
    purple: _vm.purple,
    blue: _vm.blue,
    red: _vm.red,
    green: _vm.green,
    grey: _vm.grey,
    outline: _vm.outline,
    flat: _vm.flat,
    transp: _vm.transp,
    orange: _vm.orange,
    small: _vm.small,
    p0: _vm.p0,
  },attrs:{"pill":"","disabled":_vm.disabled,"size":_vm.size},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }