<template>
  <div
    class="select-container"
    :class="{ openned: open, hasIcon: leftIcon || flag }"
  >
    <div class="label pb-2" v-if="label">{{ label }}</div>
    <b-icon v-if="leftIcon" class="left-icon" :icon="leftIcon"></b-icon>

    <div class="flag" v-if="flag">
      <flag :iso="flag" :squared="false" />
    </div>

    <v-select
      class="select"
      :placeholder="placeholder"
      :options="options"
      :value="value"
      :searchable="searchable"
      :clearable="!notClearable"
      :label="textField"
      @search:focus="open = true"
      @search:blur="open = false"
      @input="$emit('input', $event)"
    >
      <template slot="no-options">
        Aucun résultat..
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><b-icon icon="chevron-down"></b-icon></span>
      </template>
      <template v-if="flag" #option="{ flag, text }">
        <flag class="mr-2" :iso="flag" :squared="false" />
        <span>{{ text }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    }
  },
  props: {
    value: [Object, Array, Number, String],
    searchable: Boolean,
    options: Array,
    label: [Boolean, Number, String],
    placeholder: String,
    notClearable: [Boolean],
    leftIcon: String,
    flag: String,
    textField: String,
  },
}
</script>
<style lang="scss" scoped>
.label {
  color: #737e96 !important;
  font-weight: 600;
  background: none;
}
.select-container {
  position: relative;
}
.left-icon {
  transition: 0.2s;
  position: absolute;
  left: 13px;
  top: 33%;
  color: #b0b8c9;
}

.flag {
  font-size: 16px;
  transition: 0.2s;
  position: absolute;
  left: 11px;
  top: 12px;
}
.v-select {
  height: 100%;
  min-width: 150px;
  border-radius: 7px;
  border: 1px solid #ebeff6;
  color: $h-input-color !important;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  font-size: 1rem;
  color: #737e96 !important;
  div {
    border: none;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $h-input-placeholder;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $h-input-placeholder;
}
.v-select {
  --c-base: #fff; /* background */
  --c-theme: #f0f0f0; /* tags background; options hover */
  --c-border: #ccc; /* border color of root element and tags */
  --radius: 0.2em; /* border radius of root element and tags */
  --padd: 3px; /* tags spacing */
  --height: 3em; /* minimum height; can grow larger when `:multiple=true` */
}
</style>
