const getDefaultState = () => {
  return {
    campaign: null,
    brandLogo: null,
    brand: null,
    campaignSite: null,
    userLogs: null,
  }
}
const campaign = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_CAMPAIGN(state, campaign) {
      state.campaign = campaign
    },
    SET_BRAND_LOGO(state, brandLogo) {
      state.brandLogo = brandLogo
    },
    SET_BRAND_INFO(state, brandInfo) {
      state.brand = brandInfo
    },
    SET_CAMPAIGN_SITE(state, site) {
      state.campaignSite = site
    },
    REPLACE_SITE_AFTER_EDITION(state, site) {
      state.campaignSite = site
    },
    SET_CAMPAIGN_LOGS(state, logs) {
      state.userLogs = logs //{login, password}
    },
  },
  actions: {},
  getters: {
    logoSrc: (state) => {
      if (state.brandLogo && state.brandLogo.data)
        return `data:${state.brandLogo.contentType};base64, ${state.brandLogo.data}`
      else return null
    },
  },
}

export default campaign
