<template>
  <b-container>
    <router-view></router-view>
  </b-container>
</template>

<script>
export default {
  mounted() {
    if (window.zE) window.zE('webWidget', 'hide')
  },
}
</script>

<style lang="scss" scoped></style>
