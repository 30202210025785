var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:" h-100",class:{
      'shadow-card': !_vm.noShadow,
      'no-padding': _vm.p0,
      'hover-grey': _vm.hoverGrey,
      'cursor-pointer clickable': _vm.isClickable,
      'border-0': !_vm.isClickable,
      'is-active': _vm.isActive,
    },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }