var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-container select-flux",class:{ openned: _vm.open, hasIcon: _vm.leftIcon, small: _vm.small }},[(_vm.label)?_c('div',{staticClass:"label pb-2"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.leftIcon)?_c('b-icon',{staticClass:"left-icon",attrs:{"icon":_vm.leftIcon}}):_vm._e(),_c('v-select',{staticClass:"select",class:{ 'bg-white': _vm.bgWhite, 'shadow-sm': _vm.small },attrs:{"label":"name","disabled":_vm.disabled,"placeholder":_vm.placeholder,"options":_vm.optionsComp,"reduce":function (option) { return option.idStream || option.idSkeleton; },"value":_vm.value,"searchable":_vm.searchable,"clearable":false},on:{"search:focus":function($event){_vm.open = true},"search:blur":function($event){_vm.open = false},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var idImage = ref.idImage;
var name = ref.name;
return [_c('div',[(idImage > 0)?_c('img',{staticClass:"mr-3 img-flux",attrs:{"src":(_vm.env === 'production' ? '/api' : 'http://localhost:5000/api') +
              "/brand/stream/" + idImage + "/cover","alt":"flux img","width":"27px","height":"27px"}}):_c('img',{staticClass:"img-flux mr-3",attrs:{"src":name === 'MA RADIO'
              ? require('@/assets/img/cover-ma-radio.png')
              : require('@/assets/img/cover-playlist.png'),"alt":"flux img","width":"27px"}}),_vm._v(_vm._s(name)+" ")])]}},{key:"option",fn:function(ref){
              var idImage = ref.idImage;
              var name = ref.name;
return [_c('div',{staticClass:"d-flex align-items-center z-3"},[(idImage > 0)?_c('img',{staticClass:"img-flux mr-3",attrs:{"src":(_vm.env === 'production' ? '/api' : 'http://localhost:5000/api') +
              "/brand/stream/" + idImage + "/cover","alt":"flux img","width":"40px","height":"40px"}}):_c('img',{staticClass:"img-flux mr-3",attrs:{"src":name === 'MA RADIO'
              ? require('@/assets/img/cover-ma-radio.png')
              : require('@/assets/img/cover-playlist.png'),"alt":"flux img","width":"40px"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(name))])])])]}},{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('b-icon',{attrs:{"icon":"chevron-down"}})],1)]}}])},[_c('template',{slot:"no-options"},[_vm._v(" Aucun résultat.. ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }