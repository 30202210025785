<template>
  <div>
    <b-card
      @click="$emit('click')"
      class=" h-100"
      :class="{
        'shadow-card': !noShadow,
        'no-padding': p0,
        'hover-grey': hoverGrey,
        'cursor-pointer clickable': isClickable,
        'border-0': !isClickable,
        'is-active': isActive,
      }"
    >
      <slot></slot>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    noShadow: {
      type: Boolean,
      default: false,
    },
    p0: Boolean,
    hoverGrey: Boolean,
    isClickable: Boolean,
    isActive: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.shadow-card {
  box-shadow: 0 3px 4px 0 $h-box-shadow;
}
.px-0 .card-body {
  padding: 0;
}
.card-body {
  display: flex;
  flex-direction: column;
}
.hover-grey {
  transition: 0.2s;
  &:hover {
    background: $h-hover-color;
  }
}

.clickable {
  border: 2px solid #e2e2e2;
  transition: 0.3s;
  &:hover {
    border: 2px solid rgba(240, 78, 38, 0.4);
  }
  &.is-active {
    border: 2px solid #f04e23;
  }
}
</style>
