import auth from './auth.js'
import axios from './axios.js'

export default {
  logUser(params) {
    return auth.get('/user', {
      params,
    })
  },
  logOutUser(id, userToken) {
    return auth.post(`/user/${id}/logout?token=${userToken}`)
  },
  getLogo(idBrand) {
    return axios.get(`/brand/${idBrand}/logo`)
  },
  getIdentityBrands(id) {
    return axios.get(`/brand/identity/${id}`)
  },
  editUser(id, user) {
    return axios.post(`/user/${id}`, user)
  },
  changePassword(id, password) {
    return axios.post(`/user/${id}/password`, password)
  },
}
