<template>
  <div>
    <b-form-group
      class="mb-0"
      :class="{ 'required-input': requiredData, 'legend-light': textLight }"
      :label="label"
      :description="description"
    >
      <span class="left-icon" :class="{ focused: focused }" v-if="leftIcon">
        <b-icon :icon="leftIcon"></b-icon>
      </span>
      <b-form-input
        :value="valueProp"
        @input="$emit('input', $event)"
        @focus="focused = true"
        @blur="focused = false"
        :class="{ 'has-left-icon': leftIcon, 'input-error': inputError }"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :type="type"
        :state="errorState ? false : null"
        :size="size"
        v-on="$listeners"
      ></b-form-input>
      <b-form-invalid-feedback
        :class="{ 'invalid-feedback-show': errorFeedback && inputError }"
      >
        {{ errorFeedback }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      requiredData: this.required,
      focused: false,
    }
  },
  props: {
    valueProp: String,
    label: String,
    errorFeedback: String,
    placeholder: String,
    description: String,
    disabled: Boolean,
    required: Boolean,
    type: String,
    size: String,
    errorState: Boolean,
    leftIcon: String,
    textLight: Boolean,
    inputError: Boolean,
  },
  created() {
    if (this.valueProp) this.$emit('input', this.valueProp)
  },
}
</script>

<style lang="scss">
.form-group {
  position: relative;
  legend {
    color: $h-input-color !important;
    font-weight: 600;
    background: none;
  }
  .invalid-feedback-show {
    display: block !important;
  }
  input {
    border: 1px solid #ebeff6;
    border-radius: 7px;
    background-repeat: no-repeat;
    color: $h-input-color !important;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: $primary;
    }
    &.has-left-icon {
      padding-left: 38px;
    }
    &.input-error {
      border: 1px solid red !important;
    }
  }
  .left-icon {
    transition: 0.2s;
    position: absolute;
    left: 13px;
    top: 23%;
    color: $h-input-placeholder;
    &.focused {
      color: $primary;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $h-input-placeholder;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $h-input-placeholder;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $h-input-placeholder;
  }
}

.legend-light legend {
  font-weight: 400;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f1f2f3;
}
.required-input legend:after {
  content: '*';
  color: #f03c1f;
}
.form-control-lg {
  height: 46px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-text {
  width: 48px;
  border-right: none;
  background: none;
}
</style>
