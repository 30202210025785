import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'
Vue.use(Zendesk, {
  key: 'd5c739b3-e377-421b-bd91-01a8e7468f1a',
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#1F73B7',
      },
    },
  },
})
