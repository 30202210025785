import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const langLocalStorage = localStorage.getItem('lang')
const langLocalStorageParse = JSON.parse(langLocalStorage)
const navigatorLang = navigator.language.split('-')[0]

const lang = langLocalStorageParse ? langLocalStorageParse.value : navigatorLang
const locales = require.context(
  '@/translations/',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i
)

function loadLocaleMessages() {
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
  locale: lang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadLocaleMessages(),
})
