<template>
  <h-card class="text-center mt-4">
    <h4 class="text-center mb-4">Erreur serveur</h4>
    <b-row class="justify-content-center">
      <b-col cols="10" md="6" xl="4">
        <img
          src="@/assets/svg/illu-server-down.svg"
          alt="illustration server down"
          width="100%"
          class="my-5"
      /></b-col>
    </b-row>

    <p>
      Oups, une erreur s'est produite de notre côté. <br />Veuillez contacter le
      support technique ou réessayer ultérieurement.
    </p>
  </h-card>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
