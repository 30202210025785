import Vue from 'vue'
import axios from 'axios'
import router from '@/router/router.js'
import store from '@/store'
import i18n from '@/plugins/i18n.js'

let alreadyHadError = false

const client = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/api'
      : 'http://localhost:5000/api',
  withCredentials: false,
  timeout: 12000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

client.interceptors.request.use((config) => {
  let currentToken = localStorage.getItem('access_token')
  if (currentToken) config.headers.Authorization = 'Bearer ' + currentToken
  return config
})

client.interceptors.response.use(
  (response) => {
    if (alreadyHadError) alreadyHadError = false
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401 && !alreadyHadError) {
      store.dispatch('user/_clearSession')
      Vue.$toast.warning(i18n.t('errors.sessionExpired'))

      router.push({ name: 'login' })
      alreadyHadError = true
    }
    return Promise.reject(error)
  }
)

export default client
