import playerService from '@/services/playerService.js'
const getDefaultState = () => {
  return {
    playlist: [],
    playlistLoaded: false,
    playlistError: false,
  }
}
const playlist = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_PLAYLIST(state, playlist) {
      state.playlist = playlist
      state.playlistLoaded = true
    },
    CLEAR_PLAYLIST(state) {
      state.playlist = []
    },
    PLAYLIST_LOADING(state, bool) {
      state.playlistLoaded = bool
    },
    PLAYLIST_SERVER_ERROR(state, payload) {
      state.playlistError = payload
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    _playlistLoaded({ commit }, bool) {
      commit('PLAYLIST_LOADING', bool)
    },
    async _getPlaylist({ commit, rootState }, params) {
      commit('PLAYLIST_SERVER_ERROR', false)
      try {
        params.IdBrand = rootState.user.user.visibleBrands[0].id
        const res = await playerService.getPlaylist(params)
        commit('SET_PLAYLIST', res.data)
      } catch (e) {
        commit('SET_PLAYLIST', [])
        commit('PLAYLIST_SERVER_ERROR', true)
      }
    },
    _clearPlaylist({ commit }) {
      commit('CLEAR_PLAYLIST')
    },
    _resetPlaylistState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {},
}

export default playlist
