<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    if (window.zE) window.zE('webWidget', 'hide')
  },
}
</script>

<style lang="scss" scoped></style>
